<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left> </template>
        <div class="component-title">
          {{ displayLabelName("services.anonymous-service.create-order") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="selectedOrderItems && selectedOrderItems.length"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="serviceDetails">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceDetails.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="serviceDetails && serviceDetails.address"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceDetails.address }}</span
            >
          </div>
        </div>
      </li>
    </ul>
    <VeeForm
      :key="formKey"
      ref="addServiceOrderForm"
      @submit="submitForm"
      class="form inline-input edit-form"
      novalidate
      style="height: auto;"
    >
      <ul class="clebex-item-section pill" v-if="serviceOrderItems">
        <!-- Date from - date to -->
        <span class="delivery-period">{{
          displayLabelName("services.services.date-time")
        }}</span>
        <br /><br />
        <li
          class="clebex-item-section-item"
          style="margin-bottom: 0px; cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li
          class="clebex-item-section-item time"
          style="cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="showDateTimePicker"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                  :dateFormat="globalDateFormat"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                  :timeFormat="globalTimeFormat"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>

      <!-- Selected service items -->

      <span
        class="order-level"
        style="margin-left: 15px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
        >{{ displayLabelName("services.services.selected-items") }}</span
      >
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 10px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="selectedOrderItem in selectedOrderItems"
          :key="selectedOrderItem.id"
        >
          <div style="display: flex; flex-direction: row;">
            <button class="clebex-item-content-wrapper">
              <span
                class="label"
                @click="showHideSelectedItem(selectedOrderItem.id)"
              >
                <span class="highlight">{{ selectedOrderItem.name }}</span>
                <span
                  v-if="
                    !showSelectedItem(selectedOrderItem.id) &&
                      getQuantity(selectedOrderItem.id)
                  "
                >
                  ({{ getQuantity(selectedOrderItem.id) }})</span
                >
              </span>
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  v-if="checked(selectedOrderItem.id)"
                  @click="
                    selectShowHideOrderItem(
                      selectedOrderItem.id,
                      selectedOrderItem.has_quantity
                    );
                    selectOrderItem(selectedOrderItem);
                  "
                >
                  <icon icon="#cx-app1-checkmark"></icon>
                </span>
              </span>
            </button>
            <span
              class="follow-up-icons service-item-info"
              style="cursor: pointer;"
              @click="openServiceItemInfo(selectedOrderItem.id)"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </div>
          <div
            class="order-wrapper"
            v-if="showSelectedItem(selectedOrderItem.id)"
          >
            <div
              class="clebex-item-content-wrapper clebex-item-section-item order-item"
              style="margin-top: 3px !important"
              v-if="
                checked(selectedOrderItem.id) && selectedOrderItem.has_quantity
              "
            >
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label order-label"
                  for="${selectedOrderItem.id}quantity"
                  >{{ displayLabelName("services.services.quantity") }}</label
                >
                <div style="display: flex; flex-direction: row;">
                  <span
                    class="change-quantity"
                    @click="decreaseQuantity(selectedOrderItem.id)"
                    >-</span
                  >
                  <Field
                    :name="selectedOrderItem.id + '-quantity'"
                    as="input"
                    type="number"
                    disabled="true"
                    class="order-field"
                    style="width: 50px; text-align: center;"
                    :value="
                      listOfShowHideSelectedItems.find(
                        el => el.id == selectedOrderItem.id
                      ).quantity
                    "
                  />
                  <span
                    class="change-quantity"
                    @click="increaseQuantity(selectedOrderItem.id)"
                    >+</span
                  >
                </div>
              </div>
            </div>
            <div
              class="clebex-item-content-wrapper clebex-item-section-item order-item"
              style="margin-bottom: 10px !important"
              v-if="checked(selectedOrderItem.id)"
            >
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label order-label"
                  for="${selectedOrderItem.id}quantity"
                  >{{ displayLabelName("services.services.note") }}</label
                >
                <Field
                  :name="selectedOrderItem.id + '-note'"
                  as="input"
                  type="text"
                  class="order-field"
                  :keyup="updateNote(selectedOrderItem.id)"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- Service items -->

      <span class="order-level" style="margin-top: 20px; margin-left: 15px;">{{
        displayLabelName("services.service-items.service-items")
      }}</span>
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 0px;"
        v-for="(serviceOrderType, index) in serviceOrderItems"
        :key="index"
      >
        <div
          class="service-order-type"
          @click="showHideType(serviceOrderType.id)"
        >
          <span class="order-level"
            >{{ serviceOrderType.name }} ({{
              selectedOrderItems.filter(
                el => el.service_type_id == serviceOrderType.id
              ).length
            }}/{{ serviceOrderType.items.length }})</span
          ><icon
            class="order-type-icon"
            icon="#cx-hea1-arrow-left"
            :style="[
              showItemType(serviceOrderType.id)
                ? 'transform: rotate(90deg)'
                : 'transform: rotate(-90deg)'
            ]"
          />
        </div>
        <div v-if="showItemType(serviceOrderType.id)" style="margin-top: 4px;">
          <li
            class="clebex-item-section-item"
            v-for="serviceOrderItem in serviceOrderType.items"
            :key="serviceOrderItem.id"
          >
            <div
              style="display: flex; flex-direction: row;"
              v-if="
                !selectedOrderItems.find(el => el.id == serviceOrderItem.id)
              "
            >
              <button
                class="clebex-item-content-wrapper"
                @click="
                  selectShowHideOrderItem(
                    serviceOrderItem.id,
                    serviceOrderItem.has_quantity
                  );
                  selectOrderItem(serviceOrderItem);
                "
              >
                <span class="label">
                  <span class="highlight">{{ serviceOrderItem.name }}</span>
                </span>
                <span class="follow-up-icons">
                  <span
                    class="follow-up-icon-item"
                    v-if="checked(serviceOrderItem.id)"
                  >
                    <icon icon="#cx-app1-checkmark"></icon>
                  </span>
                </span>
              </button>
              <span
                class="follow-up-icons service-item-info"
                style="cursor: pointer;"
                @click="openServiceItemInfo(serviceOrderItem.id)"
              >
                <span class="follow-up-icon-item">
                  <icon icon="#cx-app1-information"></icon>
                </span>
              </span>
            </div>
          </li>
        </div>
      </ul>
      <br /><br />
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="submitForm"
      :confirm-button-label="
        displayLabelName('resources.resource-types.delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('resources.resource-types.delete-cancel-action')
      "
      :show="showPasswordModal1"
      @close="showPasswordModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("resources.resource-types.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("resources.resource-types.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>

    <!-- No password modal - START -->
    <div class="status-dialog" v-if="noPasswordModal">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <!-- Status info -->
          <br />
          <icon
            icon="#cx-app1-cross-round"
            style="--color-2: #ff3232; width: 120px; "
          />
          <div class="dialog-body">
            <div class="dialog-body-row">
              <label>
                {{
                  displayLabelName("services.anonymous.no-password-message")
                }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- No password modal - END -->
    <!-- No anonymous services modal - START -->
    <div class="status-dialog" v-if="noAnonymousServicesModal">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <!-- Status info -->
          <br />
          <icon
            icon="#cx-app1-cross-round"
            style="--color-2: #ff3232; width: 120px; "
          />
          <div class="dialog-body">
            <div class="dialog-body-row">
              <label>
                {{ displayLabelName("services.anonymous.disabled") }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- No anonymous services modal - END -->
    <!-- Password dialog - START -->
    <div class="status-dialog" v-if="showPasswordModal">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <!-- Status info -->
          <div class="dialog-body">
            <!-- Password -->
            <div class="dialog-body-row" style="flex-direction: column;">
              <label for="password">
                {{
                  displayLabelName("services.anonymous.password-message")
                }}:</label
              ><br />
              <input
                name="password"
                type="password"
                id="password"
                autocomplete="off"
                v-model="password"
                style="height: 30px; outline: none;"
              />
            </div>
          </div>
          <div class="dialog-close">
            <div class="dialog-action" @click="showPasswordModal = false">
              {{ displayLabelName("utilities", "messages", "cancel") }}
            </div>
            <div class="dialog-action" @click="$refs.submitFormBtn.click()">
              {{ displayLabelName("utilities", "messages", "ok") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Password dialog - END -->
    <!-- Service item info - START -->
    <div class="status-dialog" v-if="serviceItemInfoModal">
      <div class="dialog-wrapper" style="width: 400px !important;">
        <div class="dialog-container">
          <!-- Status info -->
          <div
            class="dialog-body"
            style="padding-top: 0px; padding-bottom: 0px; width: 100%"
          >
            <!-- Password -->
            <div
              class="dialog-body-row"
              style="flex-direction: column; width: 100%"
            >
              <ul
                class="clebex-item-section"
                style="width: 98%; margin-top: 0.3rem; margin-bottom: 1rem;"
              >
                <li class="clebex-item-section-item full-right-underline">
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-section-input">
                      <div
                        class="clebex-section-input-label"
                        style="text-align: left;"
                      >
                        {{ displayLabelName("services.service-items.name") }}
                      </div>
                      <p class="text-label" style="text-align: left;">
                        {{ serviceItemInfo.name }}
                      </p>
                    </div>
                  </div>
                  <Field
                    name="has_translations"
                    as="input"
                    rules="required"
                    type="hidden"
                    :value="name"
                  />
                </li>
                <li class="clebex-item-section-item">
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-section-input">
                      <label
                        class="clebex-section-input-label"
                        for="desc"
                        style="text-align: left;"
                        >{{
                          displayLabelName("services.service-items.type")
                        }}</label
                      >

                      <span class="service-item-info-span">
                        {{ serviceItemInfo.type }}</span
                      >
                    </div>
                  </div>
                </li>
                <li class="clebex-item-section-item">
                  <div class="clebex-item-content-wrapper">
                    <dl class="clebex-item-dl justify-start">
                      <dt class="clebex-item-dt" style="text-align: left;">
                        <div class="checkbox slide">
                          <input
                            id="hasQuantity"
                            type="checkbox"
                            v-model="serviceItemInfo.has_quantity"
                            disabled="true"
                          />
                          <label for="hasQuantity"></label>
                        </div>
                      </dt>
                      <dd
                        class="clebex-item-dd justify-start"
                        style="text-align: left;"
                      >
                        {{
                          displayLabelName(
                            "services.service-items.has-quantity"
                          )
                        }}
                      </dd>
                    </dl>
                  </div>
                </li>
                <li class="clebex-item-section-item">
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-section-input">
                      <label
                        class="clebex-section-input-label"
                        for="code"
                        style="text-align: left;"
                        >{{
                          displayLabelName("services.service-items.code")
                        }}</label
                      >
                      <Field
                        id="code"
                        name="code"
                        as="input"
                        type="text"
                        v-model="serviceItemInfo.code"
                        disabled="true"
                      />
                    </div>
                  </div>
                </li>
                <li class="clebex-item-section-item">
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-section-input">
                      <label
                        class="clebex-section-input-label"
                        for="desc"
                        style="text-align: left;"
                        >{{
                          displayLabelName("services.service-items.description")
                        }}</label
                      >

                      <span class="service-item-info-span">
                        {{ serviceItemInfo.description }}</span
                      >
                    </div>
                  </div>
                </li>
                <li class="clebex-item-section-item">
                  <div class="clebex-item-content-wrapper">
                    <div class="clebex-section-input">
                      <label
                        class="clebex-section-input-label"
                        for="price"
                        style="text-align: left;"
                        >{{
                          displayLabelName("services.service-items.price")
                        }}</label
                      >
                      <span class="service-item-info-span">
                        {{ serviceItemInfo.price }}
                        {{ serviceItemInfo.currency }}</span
                      >
                    </div>
                  </div>
                </li>
                <!-- Service Image -->
                <li
                  class="clebex-item-section-item full-right-underline"
                  v-if="serviceItemInfo.media"
                >
                  <img
                    :src="serviceItemInfo.media"
                    style="width: 90%; margin: 5%;"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="dialog-close">
            <div
              class="dialog-action"
              style="padding: 20px; padding-top: 25px; width: 100%; border-right: 0px;"
              @click="serviceItemInfoModal = false"
            >
              {{ displayLabelName("global.messages.ok") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="closeSuccessModal"
      :show="show"
      @close="closeSuccessModal"
      :confirm-button-label="
        displayLabelName('resources.resource-types.delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('resources.resource-types.delete-cancel-action')
      "
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.anonymous-service.create-order") }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "services.anonymous-service.order-is-successfully-created"
          )
        }}
      </p>
    </screen-modal>
    <!-- Service item info - END -->
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { formatDate } from "@/services/helpers";
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";

export default {
  name: "AnonymousService",
  data() {
    return {
      showPasswordModal: false,
      noPasswordModal: false,
      noAnonymousServicesModal: false,
      searchQuery: "",
      submitFormState: false,
      showDateTimePicker: false,
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      listOfShowHideTypes: null,
      listOfShowHideSelectedItems: [],
      resource: null,
      globalDateFormat: "dd.MM.YYYY",
      globalTimeFormat: "hh:mm A",
      selectedOrderItems: [],
      anonymousService: null,
      serviceDetails: null,
      serviceOrderItems: null,
      password: null,
      companyProfileChannel: null,
      resourceChangeChannel: null,
      levelChangeChannel: null,
      serviceItemChangeChannel: null,
      serviceItemInfoModal: false,
      serviceItemInfo: null,
      show: false
    };
  },
  async created() {
    await this.initialize();
  },
  watch: {
    serviceOrderItems() {
      this.listOfShowHideTypes = this.serviceOrderItems.map(item => ({
        id: item.id,
        show: false
      }));
    },
    async anonymousService() {
      this.globalDateFormat = this.anonymousService.data.service_details.date_format;
      this.globalTimeFormat = this.anonymousService.data.service_details.time_format;
      this.serviceOrderItems = this.anonymousService.data.service_items;
      this.serviceDetails = this.anonymousService.data.service_details;
      this.subscribe();
      await this.getLabels(this.serviceDetails.language);
      if (this.serviceDetails.enabled == false) {
        this.noAnonymousServicesModal = true;
      } else {
        this.noAnonymousServicesModal = false;
      }
      if (this.serviceDetails.password == false) {
        this.noPasswordModal = true;
      } else {
        this.noPasswordModal = false;
      }
    }
  },
  methods: {
    async initialize() {
      this.anonymousService = await this.getAnonymousService();
    },
    getLabels() {
      return httpServiceAuth
        .get(
          `${apiEndpoints.master.companyLabels}/all?lang=` +
            this.serviceDetails.language
        )
        .then(response => {
          this.$store.commit("translation/setTranslations", response.data, {
            root: true
          });
          this.$store.commit("translation/setGetTranslationsPending", false, {
            root: true
          });
        });
    },
    async subscribe() {
      this.companyProfileChannel = subscribe(
        "company-profile",
        [getSubdomain()],
        wsEvents.companyProfile.changed,
        this.companyProfileCallback,
        true
      );

      this.resourceChangeChannel = subscribe(
        "resource-functions",
        [getSubdomain(), this.serviceDetails.resource_id],
        wsEvents.resourceChange.changed,
        this.resourceChangeCallback,
        true
      );
      this.levelChangeChannel = subscribe(
        "levels",
        [getSubdomain(), this.serviceDetails.level_id],
        wsEvents.levelChange.changed,
        this.levelChangeCallback,
        true
      );

      this.serviceItemChangeChannel = subscribe(
        "service-item",
        [getSubdomain()],
        wsEvents.serviceItem.changed,
        this.serviceItemChangeCallback,
        true
      );
    },
    async companyProfileCallback() {
      await this.initialize();
    },
    async resourceChangeCallback() {
      await this.initialize();
    },
    async levelChangeCallback() {
      await this.initialize();
    },
    async serviceItemChangeCallback() {
      await this.initialize();
    },
    closeSuccessModal() {
      this.show = false;
      this.$router.go(0);
    },
    async getAnonymousService() {
      let url = location.href.split("/");
      let code = url[url.length - 1];
      return httpServiceAuth.get(
        `${apiEndpoints.company.anonymousService}/${code}`
      );
    },
    selectOrderItem(orderItem) {
      const hasResourceIdx = this.selectedOrderItems.findIndex(
        item => item.id === orderItem.id
      );
      if (hasResourceIdx !== -1) {
        this.selectedOrderItems.splice(hasResourceIdx, 1);
      } else {
        this.selectedOrderItems.push(orderItem);
      }
    },
    increaseQuantity(id) {
      const fieldElement = document.querySelector(`[name="${id}-quantity"]`);
      fieldElement.value++;
      this.listOfShowHideSelectedItems.find(el => el.id == id).quantity =
        fieldElement.value;
      fieldElement.dispatchEvent(new Event("input"));
      fieldElement.dispatchEvent(new Event("change"));
    },
    decreaseQuantity(id) {
      const fieldElement = document.querySelector(`[name="${id}-quantity"]`);
      if (fieldElement.value > 0) {
        fieldElement.value--;
      }
      this.listOfShowHideSelectedItems.find(el => el.id == id).quantity =
        fieldElement.value;
      fieldElement.dispatchEvent(new Event("input"));
      fieldElement.dispatchEvent(new Event("change"));
    },
    getQuantity(id) {
      if (this.listOfShowHideSelectedItems) {
        return this.listOfShowHideSelectedItems.find(el => el.id == id)
          .quantity;
      }
      return null;
    },
    updateNote(id) {
      const fieldElement = document.querySelector(`[name="${id}-note"]`);
      if (fieldElement) {
        this.listOfShowHideSelectedItems.find(el => el.id == id).note =
          fieldElement.value;
      }
    },
    showItemType(id) {
      if (this.listOfShowHideTypes) {
        return this.listOfShowHideTypes.find(item => item.id == id).show;
      }
      return false;
    },
    showHideType(id) {
      var showHide = this.listOfShowHideTypes.find(item => item.id == id).show;
      this.listOfShowHideTypes.find(item => item.id == id).show = !showHide;
    },
    showHideSelectedItem(id) {
      var showHide = this.listOfShowHideSelectedItems.find(
        item => item.id == id
      ).show;

      if (showHide == false) {
        this.listOfShowHideSelectedItems.forEach(item => {
          item.show = false;
        });
      }

      this.listOfShowHideSelectedItems.find(
        item => item.id == id
      ).show = !showHide;
    },
    selectShowHideOrderItem(id, hasQuantity) {
      var item = this.listOfShowHideSelectedItems.find(el => el.id == id);
      if (item) {
        this.listOfShowHideSelectedItems = this.listOfShowHideSelectedItems.filter(
          el => el.id != id
        );
      } else {
        this.listOfShowHideSelectedItems.forEach(item => {
          item.show = false;
        });
        this.listOfShowHideSelectedItems.push({
          id: id,
          quantity: hasQuantity == 0 ? null : 1,
          note: null,
          show: true
        });
      }
    },
    showSelectedItem(id) {
      if (
        this.listOfShowHideSelectedItems &&
        this.listOfShowHideSelectedItems.find(item => item.id == id)
      ) {
        return this.listOfShowHideSelectedItems.find(item => item.id == id)
          .show;
      }
      return false;
    },
    openServiceItemInfo(orderItemId) {
      this.serviceOrderItems.forEach(type => {
        type.items.forEach(item => {
          if (item.id == orderItemId) {
            this.serviceItemInfo = item;
            this.serviceItemInfo.type = type.name;
          }
        });
      });

      this.serviceItemInfoModal = true;
    },
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");

      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    checked(id) {
      return (
        this.selectedOrderItems &&
        this.selectedOrderItems.length &&
        this.selectedOrderItems.find(item => item.id === id)
      );
    },
    formValues() {
      let response = [];

      for (var i = 0; i < this.selectedOrderItems.length; i++) {
        let object = { quantity: null, note: "", service_status: "ORDER" };
        object.id = this.selectedOrderItems[i].id;

        object.quantity = this.listOfShowHideSelectedItems.find(
          el => el.id == this.selectedOrderItems[i].id
        ).quantity;
        object.note = this.listOfShowHideSelectedItems.find(
          el => el.id == this.selectedOrderItems[i].id
        ).note;

        response.push(object);
      }
      return response;
    },
    initSubmit() {
      if (this.serviceDetails.password) {
        this.showPasswordModal = true;
      } else {
        const { submitFormBtn } = this.$refs;
        submitFormBtn.click();
      }
    },
    submitForm(values) {
      if (values && this.submitFormState) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      this.submitFormState = false;
      values = {
        resource_id: this.serviceDetails.resource_id,
        service_status_id: 1,
        service_items: this.formValues(),
        delivery_start: dateTimeFrom,
        delivery_end: dateTimeTo,
        declaration_id: null,
        password: this.password
      };
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.company.anonymousService, values)
        .then(() => {
          this.showPasswordModal = false;
          this.show = true;
        })
        .catch(error => {
          if (error.response) {
            this.showPasswordModal = false;
            errorHandler(error.response, this.findElement());
            this.password = "";
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    )
  },
  beforeUnmount() {
    if (this.companyProfileChannel) {
      unsubscribe(this.companyProfileChannel);
      this.companyProfileChannel = null;
    }
    if (this.resourceChangeChannel) {
      unsubscribe(this.resourceChangeChannel);
      this.resourceChangeChannel = null;
    }
    if (this.levelChangeChannel) {
      unsubscribe(this.levelChangeChannel);
      this.levelChangeChannel = null;
    }
    if (this.serviceItemChangeChannel) {
      unsubscribe(this.serviceItemChangeChannel);
      this.serviceItemChangeChannel = null;
    }
  }
};
</script>
<style>
.order-wrapper {
  border: 1px solid lightgray !important;
  border-top: 0px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 3px;
}
.order-item {
  border-radius: 0px !important;
  background: transparent !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
.order-label {
  padding-left: 0px !important;
}
.order-field {
  background: white !important;
  padding: 5px !important;
  height: 2rem !important;
}
.service-item-info {
  background-color: white;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}
.delivery-period {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
.order-type-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  transform: rotate(-90deg);
  margin-right: 15px;
}
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0px !important;
  margin-left: 7px;
}
.service-order-type {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 0.8rem;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  align-items: center;
}
.change-quantity {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.service-item-info-span {
  color: #999999;
  width: 100% !important;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.3;
  font-size: 16px;
  text-align: left;
}
</style>
